export const Config = {
  Heading: "About Us",
  Subheading: "6 years of experience in web2 & web3 solutions",
  Paras: [
    "Founded in 2016 under the brand Evention, we provided IT Tech solutions for the events industry and Team for enterprises for their instant messaging needs.",
    "With the support of our in-house developer and the trend of decentralized apps, Cloverlemon has made the transition from a web2 to a web3 company.",
    "Today, Cloverlemon has activated Metaverse projects from virtual NFTs galleries to events supporting NFTs launch.",
  ],
  Button: "Know more",
  Image: "/assets/home_about/1.jpg",
  Skill_1: {
    progress: "90",
    thin_heading: "Project",
    bold_heading: "consulting",
  },
  Skill_2: {
    progress: "75",
    thin_heading: "App",
    bold_heading: "Development",
  },
};
