import React, {useState,useEffect} from "react";

// react-window-size-listener

const ProjectDetailsImages = ({ Config }) => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, [window.innerWidth]);


  return (
    <section className="projdtal">
      <h2 style={{ display: "none" }}> &nbsp; </h2>
      <div className="container-fluid">
        <div className={`${width > 767 ? "justified-gallery" : ""}`}>
          <div className="row">
            {Config.Images.map((item, index) => (
              <div key={index} className="col-md-6 pr-0">
                <a href={item}>
                  <div className="bg-img-header">
                    <img alt="" src={item} />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetailsImages;
