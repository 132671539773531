import React from "react";
import { Formik, Form, Field } from "formik";
import { Config } from "../../configuration/Footer_Config";
import { To_User, To_Team } from "../../configuration/Email_Config";
import emailjs from "@emailjs/browser";
import { Box } from "@mui/material";
import PhoneInputField from "../PhoneInputField";

const Footer = (props) => {
  const sendEmail = async ({ name, email, phone, msg }) => {
    try {
      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_USER_TEMPLATE_ID,
        {
          from_name: To_User.from_name,
          to_name: name,
          message: To_User.message,
          to_mail: email,
        },
        process.env.REACT_APP_PUBLIC_KEY
      );

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_COMPANY_TEMPLATE_ID,
        {
          from_name: To_Team.from_name,
          to_name: To_Team.to_name,
          message: To_Team.message,
          user_name: name,
          user_mail: email,
          user_phone: phone,
          user_msg: msg,
          to_mail: To_Team.to_mail,
        },
        process.env.REACT_APP_PUBLIC_KEY
      );

      alert("Thank you for reaching out. We'll get back to you shortly.");
    } catch (error) {
      console.log(error);
    }
  };

  function validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  const scrollToItem = (top) => {
    window.scrollTo({
      top: top ? top : 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <footer className="footer-half section-padding pb-0" ref={props.exact_ref}>
      <div className="container">
        <div className="row">
          <div className="col-lg-5">
            <div className="cont">
              <img
                src={Config.Brand_Light}
                style={{ maxWidth: "150px", width: "100%" }}
              />
              <div className="con-info custom-font">
                <ul>
                  {Config.ContactInfo.map((i, k) => {
                    return (
                      <li key={k}>
                        <span>{i.type} : </span> {i.text}
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="social-icon">
                <h6 className="custom-font stit simple-btn">
                  {Config.Heading1}
                </h6>
                <div className="social">
                  {Config.Social.map((i, k) => {
                    return (
                      <a href={i.url} className="icon" key={k}>
                        <i className={`fab ${i.icon}`}></i>
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <div className="subscribe mb-50">
              <h6 className="custom-font stit simple-btn">{Config.Heading2}</h6>
              <p>{Config.Para}</p>
              <Formik
                initialValues={{
                  name: "",
                  email: "",
                  phone: "",
                  msg: "",
                }}
                onSubmit={async (values) => {
                  await sendEmail(values);
                  // Reset the values
                  values.name = "";
                  values.email = "";
                  values.phone = "";
                  values.msg = "";
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <div
                      className="custom-font d-flex flex-column"
                      style={{ gridGap: "1rem" }}
                    >
                      {/* form-group  */}

                      <Field
                        type="text"
                        name="name"
                        placeholder={Config.FieldPlaceholder.name}
                        required
                      />

                      <Field
                        validate={validateEmail}
                        type="email"
                        name="email"
                        placeholder={Config.FieldPlaceholder.mail}
                        required
                      />

                      <Field
                        type="tel"
                        name="phone"
                        placeholder={Config.FieldPlaceholder.phone}
                        component={PhoneInputField}
                      />

                      <Field
                        type="text"
                        name="msg"
                        align="left"
                        placeholder={Config.FieldPlaceholder.message}
                        style={{ lineHeight: "3rem" }}
                      />

                      {errors.email && touched.email && (
                        <div>{errors.email}</div>
                      )}

                      <button className="cursor-pointer">
                        {Config.Button}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {Config.ShowInstagramPosts && (
              <div className="insta">
                <h6 className="custom-font stit simple-btn">Instagram Post</h6>
                <div className="insta-gallary">
                  <a href="#0">
                    <img src="/img/insta/1.jpg" alt="" />
                  </a>
                  <a href="#0">
                    <img src="/img/insta/2.jpg" alt="" />
                  </a>
                  <a href="#0">
                    <img src="/img/insta/3.jpg" alt="" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="copyrights text-center">
          <p>{Config.Copyright}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
