import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { Config } from "../../configuration/home/Home_Services_Config";

const Services3 = (props) => {
  return (
    <section className="services section-padding" ref={props.exact_ref}>
      <div className="container">
        <div className="sec-head custom-font tr-head">
          <h6>{Config.ThinHeader}</h6>
          <h3>{Config.BoldHeader}</h3>
          <span className="tbg">{Config.LowOpacityHeader}</span>
        </div>
        <div className="row">
          {Config.Services.map((i, k) => {
            return (
              <div
                className="col-lg-4 wow fadeInUp"
                data-wow-delay=".3s"
                key={k}
              >
                <div className={`step-item ${i.extra_class}`}>
                  <img src={i.icon} style={{ width: "50px" }} alt="icons" />
                  <h6>{i.heading}</h6>
                  <Accordion sx={{ bgcolor: "#ffffff44" }}>
                    <AccordionSummary
                      expandIcon={
                        <span
                          className=" pe-7s-angle-down"
                          style={{ fontSize: "40px", fontWeight: "500" }}
                        ></span>
                      }
                    >
                      <Typography sx={{ color: "#fff" }}>{i.para}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ul>
                        {i.content.map((text, index) => (
                          <li
                            key={index}
                            style={{
                              color: `${
                                i.extra_class !== "xcolor" ? "#fff" : "#000"
                              }`,
                            }}
                          >
                            {text}
                          </li>
                        ))}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services3;
