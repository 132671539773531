export const Config = {
  LowOpacityHeader: "Portfolio",
  ThinHeader: "Portfolio",
  BoldHeader: "Our Works.",
  Sections: [
    {
      img: "/assets/home_portfolio/1.png",
      heading: "NFT Gallery",
      sub_heading: "Refinable Metaverse NFT Gallery",
      slug: "1",
    },
    {
      img: "/assets/home_portfolio/2.png",
      heading: "NFT",
      sub_heading: "MANDALAFON NFT Launch",
      slug: "2",
    },
    {
      img: "/assets/home_portfolio/3.jpg",
      heading: "METAVERSE",
      sub_heading: "Metaverse Project",
      slug: "3",
    },
  ],
  MaxItemsPerViewLG: 4,
  MaxItemsPerViewMD: 2,
  MaxItemsPerViewSM: 1,
};
