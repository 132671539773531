import React from "react";
import "./styles/globals.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Home from "./Pages/Home";
import PortfolioWork from "./Pages/Portfolio";
import Search from "./Pages/Search";
import Cursor from "./components/Cursor";
import ScrollToTop from "./components/Scroll-to-top";
import LoadingScreen from "./components/Loading-Screen";
import DarkTheme from "./layouts/Dark";

const App = () => {
  return (
    <Router>
      <Cursor />
      <LoadingScreen />
      <DarkTheme />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/portfolio/:slug" element={<PortfolioWork />} />
        <Route path="/search/:tag" element={<Search />} />
        <Route path="/search" element={<Search />} />
      </Routes>

      <Helmet>
        <script
          id="initWow"
          strategy="lazyOnload"
        >{`new WOW().init();`}</script>
      </Helmet>
    </Router>
  );
};

export default App;
