export const Config = {
  ShowInstagramPosts: false,
  Brand: "CloverLemon",
  Brand_Light: "/assets/navbar/logo_light.png",
  Brand_Dark: "/assets/navbar/logo_dark.png",
  ContactInfo: [
    {
      type: "Email",
      text: "enquiry@cloverlemon.com",
    },
    {
      type: "Address",
      text: "176 Orchard Rd, #05-05 The Centrepoint, Singapore 238843",
    },
    {
      type: "Phone",
      text: "(+65) 6208 7038",
    },
  ],
  Heading1: "FOLLOW US",
  Heading2: "CONTACT US",
  Para: "Send us an enquiry here!",
  Button: "SUBMIT",
  FieldPlaceholder: {
    name:"Name",
    mail:"Email Address",
    phone:"Phone",
    message:"Message"
  },
  Copyright: "Made by CloverLemon © 2022",
  // CompanyName: "Clover Lemon",
  CompanyUrl: "#0",
  Social: [
    {
      name: "instagram",
      icon: "fa-instagram",
      visible: true,
      url: "https://www.instagram.com/evention_sg",
    },
    {
      name: "facebook",
      icon: "fa-facebook",
      visible: true,
      url: "https://www.facebook.com/EVENTIONAPP",
    },
    {
      name: "linkedin",
      icon: "fa-linkedin",
      visible: true,
      url: "https://sg.linkedin.com/company/eventionapp-singapore",
    },
  ],
};
