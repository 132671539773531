
export const To_User = {
    from_name: "CloverLemon Team",
    message: "Thank you for subscribing to our newsletter.",
};

export const To_Team = {
    from_name: "CloverLemon Mail Service",
    to_name: "CloverLemon Team",
    message: "You have a new user subscribe! 🎉",
    to_mail: "infosg@eventionapp.com",
};