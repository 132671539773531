export const Config = {
  Services: [
    {
      heading: "WEB3 services",
      para: "Our WEB3 Solutions",
      extra_class: "xtop",
      icon: "/assets/services_icons/NFT.png",
      content: ["NFT Project Management", "Metaverse"],
    },
    {
      heading: "NFT Services",
      para: "Our NFT Solutions",
      extra_class: "xcolor",
      icon: "/assets/services_icons/WEB3_1.png",
      content: [
        "Smart Contract Creation for NFTs",
        "Whitelists",
        "Metadata Generation",
        "NFT marketplace development on Opensea",
        "Metamask Wallet Integration",
        "NFT Project Website Development",
        "Community Building - Discord, Twitter Management",
      ],
    },
    {
      heading: "Virtual Event Services",
      para: "Our Virtual Event Services",
      extra_class: "xbottom",
      icon: "/assets/services_icons/WEB3_2.png",
      content: [
        "Branded Virtual World Building & Engagement",
        "Virtual Exhibitions",
        "Virtual Conferences",
        "Gamification",
        "Unique Login IDs",
        "Event Attendee Reports",
      ],
    },
  ],

  ThinHeader: "Best Features",
  BoldHeader: "Services.",
  LowOpacityHeader: "Services",
  Button: "Discover More",
};
