import React from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import ProjectDetailsHeader from "../../components/Project-details-header";
import ProjectDetailsIntroduction from "../../components/Project-details-introduction";
import ProjectDetailsImages from "../../components/Project-details-images";
import SmallFooter from "../../components/Small-footer";
import { Config } from "../../configuration/portfolio_project/Project_Config";


const ProjectDetailsDark = () => {
  const { slug } = useParams();

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    const result = Config.Projects.filter((item) => item.slug == slug);    
    setData(result[0]);
  });

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <>
      <Navbar nr={navbarRef} lr={logoRef} isOtherPage={true} />

      {data !== null && (
        <>
          <ProjectDetailsHeader Config={data} />
          <ProjectDetailsIntroduction Config={data} />
          <ProjectDetailsImages Config={data} />
        </>
      )}

      <SmallFooter />
    </>
  );
};

export default ProjectDetailsDark;
