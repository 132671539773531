export const Config = {
  ThinHeading: "WHAT PEOPLE ARE SAYING",
  BoldHeading: "Testimonials",
  LowOpacityHeading: "Testimonials",
  Testimonials: [
    {
      para: "CloverLemon created a professional NFT Launch Platform for our NFTs, Discord channel and Twitter. Literally a turnkey solution for a successful NFT Project and the results from the launch day affirmed that CloverLemon solutions work.",
      img: "/assets/home_testimonials/1.png",
      name: "Master Fon",
      designation: "Founder of MandalaFON",
    },    
  ],
};
