export const Config = {
  NavItems: [
    {
      text: "Home",
      url: "",
    },

    {
      text: "About",
      url: "",
    },
    {
      text: "Services",
      url: "",
    },
    {
      text: "Portfolio",
      url: "",
    },
    {
      text: "Testimonials",
      url: "",
    },
    {
      text: "Contact",
      url: "",
    },
  ],
  Brand_Light: "/assets/navbar/logo_light.png",
  Brand_Dark: "/assets/navbar/logo_dark.png",
  ContactInfo: [
    {
      type: "Email",
      text: "cloverlemon_support@website.com",
    },
    {
      type: "Address",
      text: "A32 , Ave 15th Street, Door 211, San Franciso, USA 32490.",
    },
    {
      type: "Phone",
      text: "(+1) 2345 678 44 88",
    },
  ],
};
