import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { handleMobileDropdown } from "../../common/navbar";
import { Config } from "../../configuration/Navbar_Config";
import { Box } from "@mui/material";

const Navbar = ({ nr, theme, action, isOtherPage }) => {
  const navigate = useNavigate();

  const [isNave, setIsNav] = React.useState(true);

  const handleClick = () => {
    navigate("/search");
  };

  return (
    <nav
      ref={nr}
      className={`navbar navbar-expand-lg change${
        theme === "themeL" ? "light" : ""
      }`}
    >
      <div className="container">
        <Link to="/">
          {theme ? (
            theme === "themeL" ? (
              <img
                src={Config.Brand_Dark}
                style={{ maxWidth: "150px", width: "100%" }}
              />
            ) : (
              <img
                src={Config.Brand_Dark}
                style={{ maxWidth: "150px", width: "100%" }}
              />
            )
          ) : (
            <img
              src={Config.Brand_Light}
              style={{ maxWidth: "150px", width: "100%" }}
            />
          )}
        </Link>

        <button
          className="navbar-toggler mr-2"
          type="button"
          onClick={() => {
            setIsNav(!isNave);
            handleMobileDropdown();
          }}
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar">
            <i className="fas fa-bars"></i>
          </span>
        </button>

        {/* collapse */}

        <div
          className={` ${isNave && "collapse"}   navbar-collapse`}
          id="navbarSupportedContent"
        >
          <ul className="navbar-nav ml-auto">
            {!isOtherPage &&
              Config.NavItems.map((i, k) => {
                return (
                  <li className="nav-item" key={k}>
                    <a onClick={(e) => action(k)}>
                      <a className="nav-link">{i.text}</a>
                    </a>
                  </li>
                );
              })}
            {isOtherPage &&
              Config.NavItems.map((i, k) => {
                return (
                  <li className="nav-item" key={k} passhref="true">
                    <Link to={k === 0 ? "/" : `/#${i.text.toLowerCase()}`}>
                      <a className="nav-link">{i.text}</a>
                    </Link>
                  </li>
                );
              })}
          </ul>
          <div className="search" onClick={handleClick}>
            <Box
              sx={{
                display: "flex",
                gridGap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span
                className="icon pe-7s-search cursor-pointer"
                style={{ display: "block" }}
              ></span>

              <p
                style={{
                  display: !isNave ? "block" : "none",
                }}
              >
                Search
              </p>
            </Box>
            <div className="search-form text-center custom-font"></div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
