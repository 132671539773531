import React from "react";

const ProjectDetailsIntroduction = ({ Config }) => {
  return (
    <section className="intro-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4">
            <div className="htit">
              <h4>
                <span>01 </span> {Config.Heading1}
              </h4>
            </div>
          </div>
          <div className="col-lg-8 offset-lg-1 col-md-8 mb-30">
            <div className="text">
              {Config.Text.map((text, index) => (
                <p key={index} className="extra-text">
                  {text}
                </p>
              ))}
            </div>
          </div>

          {Config.List && (
            <>
              <div className="col-lg-3 col-md-4">
                <div className="htit">
                  <h4>
                    <span>02</span> {Config.List.title}
                  </h4>
                </div>
              </div>
              <div className="text col-lg-8 offset-lg-1 col-md-8 mb-30">
                <ul className="project-points">
                  {Config.List.points.map((text, index) => (
                    <li key={index * 2} style={{ listStyleType: "initial" }}>
                      <p className="extra-text">{text}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default ProjectDetailsIntroduction;
