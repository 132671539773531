import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import JsonSearch from "search-array";
import Navbar from "../../components/Navbar";
import { Config } from "../../configuration/portfolio_project/Project_Config";
import FlipMove from "react-flip-move";

const Search = () => {
  const { tag } = useParams();
  const [tags, setTags] = useState([]);
  const [query, setQuery] = useState("");
  const [result, setResult] = useState([]);

  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);
  React.useEffect(() => {
    var navbar = navbarRef.current,
      logo = logoRef.current;
    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  const searcher = new JsonSearch(Config.Projects, {
    indice: {
      Tags: "Tags",
      SubCategory: "SubCategory",
      MainCategory: "MainCategory",
    },
  });

  useEffect(() => {
    if (tag !== undefined) {
      setTags([tag]);
    }
  }, []);

  useEffect(() => {
    let data = searcher.query(`${query} ${tags}`);
    setResult(data);
  }, [query]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
  };


  return (
    <div>
      <Navbar nr={navbarRef} lr={logoRef} isOtherPage={true} />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <input
          type="text"
          autoFocus
          autoCorrect
          style={{
            maxWidth: "300px",
            width: "100%",
            height: "40px",
            borderRadius: "5px",
            border: "none",
            textIndent: "10px",
            margin: "0 .5rem",
          }}
          placeholder="Search"
          onChange={handleChange}
        />
      </div>

      <FlipMove
        style={{
          display: "grid",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        {result.length > 0 &&
          result.map((item, index) => (
            <Link to={`/portfolio/${item.slug}`}>
              <tr key={index} className="search-result">
                <th>
                  <img
                    src={item.MainImage}
                    alt={item.SubCategory}
                    style={{ maxWidth: "150px" }}
                  />
                </th>
                <th>
                  <div>
                    <h6>{item.SubCategory}</h6>
                  </div>
                </th>
              </tr>
            </Link>
          ))}
      </FlipMove>
    </div>
  );
};

export default Search;
