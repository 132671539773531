import React from "react";

const ProjectDetailsHeader = ({ Config }) => {
  return (
    <section className="page-header proj-det">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-9">
            <div className="cont">
              <h6>{Config.SubCategory}</h6>
              {Config.MainCategory?.length > 0 && (
                <h2>{Config.MainCategory}</h2> 
              )}
            </div>
            
          </div>
          {/* <div className="col-lg-7 col-md-9 tags row mb-3 ml-1" > 
            {Config.Tags.map((tag,index) => (
              <h6 key={index}>
                <a href="#">{tag}</a>
              </h6>
            ))}
          </div> */}
        </div>
      </div>
      <img src={Config.MainImage} className="bg-img-header" />
    </section>
  );
};

export default ProjectDetailsHeader;
