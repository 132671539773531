export const Config = {
  Projects: [
    {
      slug: 1,
      SubCategory: "Refinable Metaverse NFT Gallery",
      MainCategory: "NFT Gallery",
      MainImage: "/assets/portfolio_work/1/Banner.png",
      Heading1: "Introduction",
      Tags: ["NFT", "Metaverse", "Web3"],
      Text: [
        "Organize art shows, auctions, and other events to display, promote, or sell your NFT collection easily in the metaverse!",
      ],
      Images: [
        "/assets/portfolio_work/1/1.jpg",
        "/assets/portfolio_work/1/2.jpg",
        "/assets/portfolio_work/1/3.jpg",
      ],
    },
    {
      slug: 2,
      SubCategory: "MANDALAFON NFT Launch",
      MainCategory: "MandalaFON",
      MainImage: "/assets/portfolio_work/2/Banner.png",
      Heading1: "Introduction",
      Tags: ["NFT", "Opensea", "Web3", "Web", "development"],
      Text: [
        "MANDALAFON is a collection of 1,011 avatars that has high symbolism and convey a message of positivity that triggers our subconscious mind healing through ancient arts.",
      ],
      List: {
        title: "Our role includes",
        points: [
          "Community engagement & building.",
          "Discord, Twitter and website development.",
          "Minting of NFT into blockchain network.",
          "Launch party.",
          "Wallet creation and Airdrop of NFT.",
          "Roadmap planning.",
        ],
      },
      Images: [
        "/assets/portfolio_work/2/1.png",
        "/assets/portfolio_work/2/2.png",
        "/assets/portfolio_work/2/3.png",
        "/assets/portfolio_work/2/4.png",
        "/assets/portfolio_work/2/5.png",        
      ],
    },
    {
      slug: 3,
      SubCategory: "Metaverse Project",
      MainCategory: "METAVERSE",
      MainImage: "/assets/portfolio_work/3/Banner.jpg",
      Heading1: "Introduction",
      Tags: ["Metaverse", "Web3", "Virtual", "Events"],
      Text: [
        "Create and customize your virtual avatar and explore the metaverse! Organize virtual exhibitions, live webinars and breakout sessions or even hold live concerts in the metaverse to design a unique  event experience like no others for your attendees.",
        "Add gamification such as Scavenger Hunts to increase attendee interactions. Easily brand you and your sponsor around the metaverse.",
      ],
      Images: [
        "/assets/portfolio_work/3/1.jpg",
        "/assets/portfolio_work/3/2.jpg",
        "/assets/portfolio_work/3/3.jpg",
        "/assets/portfolio_work/3/4.jpg",
        "/assets/portfolio_work/3/5.jpg",
      ],
    },
  ],
};
