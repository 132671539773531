export const Config = {
  SliderImages: [
    "/assets/slider/1.jpg",
    "/assets/slider/2.jpg",
    "/assets/slider/3.jpg",
  ],
  ButtonText: "Discover Work",
  Slider: [
    {
      id: 1,
      title: {
        first: "Unique brand",
        second: "About",
      },
      para: "A web3.0 blockchain solutions provider transitioned from a web2.0 company providing IT Tech solutions for the events industry.",
      button: "Discover Work",
      url: "",
    },
    {
      id: 2,
      title: {
        first: "UNIQUE VISION",
        second: "Metaverse",
      },
      para: "Design and build virtual space for events, exhibitions or any occasion that you have never imagined before.",
      button: "Discover Work",
      url: "/work",
    },
    {
      id: 3,
      title: {
        first: "UNIQUE APPROACH",
        second: "NFTs",
      },
      para: "Turnkey solutions for your next NFTs launch from consultation, discord and twitter management with community building until minting day and beyond.",
      button: "Discover Work",
      url: "",
    },
  ],
};
